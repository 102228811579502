import React from "react";
import {
  PluginActionEditor,
  PluginActionForm,
  PluginActionResponse,
} from "PluginActionEditor";
import ModulePluginActionToolbar from "ee/pages/Editor/ModuleEditor/ModulePluginActionEditor/components/ModulePluginActionToolbar";
import { useLocation } from "react-router";
import { identifyEntityFromPath } from "navigation/FocusEntity";

const ModulePluginActionEditor = () => {
  const { pathname } = useLocation();
  const entity = identifyEntityFromPath(pathname);

  return (
    <PluginActionEditor actionId={entity.id}>
      <ModulePluginActionToolbar />
      <PluginActionForm />
      <PluginActionResponse />
    </PluginActionEditor>
  );
};

export default ModulePluginActionEditor;
