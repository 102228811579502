import React from "react";
import { DocsMenuItem } from "PluginActionEditor";
import { MenuSeparator } from "@appsmith/ads";
import { DeleteModuleMenuItem } from "./DeleteModuleMenuItem";

export const ToolbarMenu = () => {
  return (
    <>
      <DocsMenuItem />
      <MenuSeparator />
      <DeleteModuleMenuItem />
    </>
  );
};
