import React from "react";
import { DocsMenuItem } from "PluginActionEditor";
import { MenuSeparator } from "@appsmith/ads";
import { DeleteWorkflowMenuItem } from "./DeleteWorkflowMenuItem";

export const ToolbarMenu = () => {
  return (
    <>
      <DocsMenuItem />
      <MenuSeparator />
      <DeleteWorkflowMenuItem />
    </>
  );
};
