import React from "react";
import {
  PluginActionEditor,
  PluginActionForm,
  PluginActionResponse,
} from "PluginActionEditor";
import WorkflowPluginActionToolbar from "ee/pages/Editor/WorkflowEditor/WorkflowPluginActionEditor/components/WorkflowPluginActionToolbar";
import { useLocation } from "react-router";
import { identifyEntityFromPath } from "navigation/FocusEntity";

const WorkflowPluginActionEditor = () => {
  const { pathname } = useLocation();
  const entity = identifyEntityFromPath(pathname);

  return (
    <PluginActionEditor actionId={entity.id}>
      <WorkflowPluginActionToolbar />
      <PluginActionForm />
      <PluginActionResponse />
    </PluginActionEditor>
  );
};

export default WorkflowPluginActionEditor;
